<template>
  <div>
    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
      {{ header }}
    </b-card-title>
    <b-card-text class="mb-2">
      {{ altHeader }}
    </b-card-text>

    <!-- form -->
    <validation-observer ref="loginValidation" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- email -->
        <b-form-group label="Email" label-for="login-email">
          <validation-provider #default="{ errors }" name="Email" rules="required|email">
            <b-form-input
              id="login-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false : null"
              name="login-email"
              placeholder="usuario@correo.com"
              autocomplete="off"
              @input="checkEmailRecovery.show = false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- TODO: forgot password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">Password</label>
            <!-- <b-link :to="{name:'auth-forgot-password-v2'}"> -->
            <!-- <small>Recuperar Password?</small> -->
            <!-- </b-link> -->
          </div>
          <validation-provider #default="{ errors }" name="Password" rules="required|min:5">
            <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
              <b-form-input
                id="login-password"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                placeholder="············"
                autocomplete="off"
              />
              <b-input-group-append is-text>
                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Recovery password -->

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" block :disabled="invalid || $apiCall.state" @click="authentication"> Ingresar </b-button>
        <div class="text-center">
          <b-button type="submit" variant="flat-primary" @click="recoveryPassReq"> Olvidé mi contraseña </b-button>
        </div>
        <b-modal v-model="modalRecovery" @ok="confirmRecovery()">
          <b-card centered title="Recuperación de contraseña"> Confirma para seguir el proceso de recuperación </b-card>
        </b-modal>
        <div v-if="checkEmailRecovery.show" class="text-center mb-1">
          <span style="color: red">{{ checkEmailRecovery.message }}</span>
        </div>
      </b-form>
    </validation-observer>
    <b-card-text v-if="showRegisterLink" class="text-center">
      <span>Nuevo en la página? </span>
      <b-link :to="{ name: 'register' }">
        <span>&nbsp;Crea una cuenta</span>
      </b-link>
    </b-card-text>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormGroup, BCard, BModal, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm, BButton, BLink } from 'bootstrap-vue'
import { required, email, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BLink,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  props: {
    header: {
      type: String,
      default: ''
    },
    altHeader: {
      type: String,
      default: ''
    },
    showRegisterLink: {
      type: Boolean,
      default: true
    },
    showRememberMe: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      checkEmailRecovery: {
        show: false,
        message: ''
      },
      status: '',
      password: '',
      modalRecovery: false,
      userEmail: '',
      remember: false,
      recoveryMessage: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
      min
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
    // usermail() {
    //   return this.$store.state.userStore.user.email
    // }
  },
  methods: {
    recoveryPassReq() {
      if (this.userEmail && this.$refs.loginValidation.errors.Email.length === 1) {
        this.checkEmailRecovery.show = true
        this.userEmail = ''
        this.checkEmailRecovery.message = 'Formato de correo inválido'
        this.$refs.loginValidation.reset()
      } else if (!this.userEmail) {
        this.checkEmailRecovery.show = true
        this.checkEmailRecovery.message = 'Es necesario el correo'
      } else {
        this.$store.state.userStore.user.email = this.userEmail
        this.modalRecovery = true
      }
    },
    confirmRecovery() {
      const self = this
      return this.$store.dispatch('userStore/recoveryPassReq', self.userEmail).then(res => {
        if (!res) {
          self.$noti.error('El correo no existe')
        } else {
          self.$noti.success('Se ha enviado un código a tu correo para recuperar tu contrseña ')
          this.$router.push('/RecoveryPass')
        }
      })
    },
    authentication() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('userStore/authentication', { email: this.userEmail, password: this.password })
            .then(() => {
              this.$emit('onLogin')
              if (this.remember) localStorage.setItem('remember', 'true')
              // this.trackGA(this.userEmail)
            })
            .catch(err => this.$noti.error(err.toString()))
        } else this.$noti.warn('Debés llenar el formulario')
      })
    }
    // trackGA(email) {
    //   this.$gtag.event('login', {
    //     // 'event_category': 'user',
    //     // 'event_label': 'New User Register',
    //     'value': email
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
</style>
